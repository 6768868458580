export const steps = [
  {
    label: 'Requester',
    status: 'requester',
    email: 'vladimirdanielyan@gmail.com',
    index: 0,
    processing: false,
  },
  {
    label: 'SLT Management',
    status: 'slt_management',
    email: 'l.grigoryan@uwcdilijan.am',
    index: 1,
    processing: false,
  },
  {
    label: 'SLT Approval',
    status: 'slt',
    email: 'l.grigoryan@uwcdilijan.am',
    // email: 'l.asoyan@uwcdilijan.am',
    // email: 'vladimirdanielyan@gmail.com',
    index: 2,
    processing: false,
  },
  {
    label: 'Facility Manager Approval',
    email: 's.hakobyan@uwcdilijan.am',
    // email: 'vladimirdanielyan@gmail.com',
    type: 'confirmation',
    status: 'facility',
    index: 3,
    processing: false,
  },
  {
    label: 'Requester Delivery',
    email: 'vladimirdanielyan@gmail.com',
    status: 'done',
    index: 4,
    processing: false,
  },
]