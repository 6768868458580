/**
 * @description - The document create edit page view
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useState, useEffect } from 'react';
// Local components
import { LoadingScreen } from 'src/components';
import { useParams } from 'react-router-dom';
import { useLocalStorage, useAuth } from "src/hooks";
import { useDispatch, useSelector } from "src/redux/store";
// Local views
import DocumentFormNew from 'src/pages/docs/documents/DocumentFormNew';
import { getPlatformProjects, getContractTemplatesByProjectUid } from 'src/redux/slices';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export default function DocumentPageNewEdit({ isEditPage }: ({isEditPage: boolean})) {
  // Selected company
  const [selectedCompany] = useLocalStorage('company', {});
  // Get user and companies
  const { user } = useAuth();
  // Dispatch
  const dispatch = useDispatch();
  const { id } = useParams();
  // ======================================== THE VIEW DATA INITIALIZATION ========================================== //
  const [selectedProjectUid, setSelectedProjectUid] = useState<string>('');
  // The global state
  const { platformProjects, isLoading: isLoadingProjects, error: projectsError } = useSelector((state) => state.platformProjects);
  const { documents, document, isLoading: isLoadingDocuments, error: documentsError } = useSelector((state) => state.documents);
  useEffect(() => {
    if (selectedCompany?.company_uid && user?.uid) {
      dispatch(getPlatformProjects(user.uid, selectedCompany.company_uid));
    }
  }, [selectedCompany, user]);

  useEffect(() => {
    if (selectedCompany?.company_uid && selectedProjectUid && user?.uid) {
      dispatch(getContractTemplatesByProjectUid(selectedProjectUid, user.uid, selectedCompany.company_uid));
    }
  }, [selectedCompany, selectedProjectUid, user]);

  const onSubmitDocument = () => {
    if (id) {

    } else {

    }
  }

  if (isLoadingProjects || isLoadingDocuments) {
    return <LoadingScreen />
  }

  if (id) {
    return (<p>isEdit</p>)
  }

  // return <DocumentFormNew
  //   currentDocument={document}
  //   projects={platformProjects}
  //   templates={documents}
  //   onProjectChange={setSelectedProjectUid}
  //   onSuccessSubmit={(data) => {}}
  // />
  return <LoadingScreen />
}
