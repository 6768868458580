/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  collection,
  doc,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
// Paths
import { DEFAULT_NUMBER_OF_DATA, documentsPaths } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export function queryNewRequest() {
  return doc(collection(getFirestore(), 'requests'));
}

export function queryRequestByUid(docUid: string) {
  return doc(getFirestore(), 'requests', docUid);
}

export function getRequestsQuery(isRepeatable = true) {
  if (isRepeatable) {
    return query(
      collection(getFirestore(), 'requests'),
      where('project_uid', '==', 'ZYUic1Pi8zd1wTpETBop'),
      where('company_uid', '==', 'rdovUxTkloT2QAHL2q8CqRsKJ552'),
      where('active', '==', true),
      orderBy('created', 'desc')
    )
  } else {
    return query(
      collection(getFirestore(), 'requests'),
      where('project_uid', '==', 'ZYUic1Pi8zd1wTpETBop'),
      where('company_uid', '==', 'rdovUxTkloT2QAHL2q8CqRsKJ552'),
      where('active', '==', true),
      where('visible', '==', true),
      orderBy('created', 'desc')
    )
  }
}
