/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  collection,
  Timestamp, startAt, doc,
} from 'firebase/firestore';
// Paths
import { DEFAULT_NUMBER_OF_DATA, groupsPaths } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export function queryFetchGroupsByCompanyUid(
  companyUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // The document reference in which the query should do fetching
  const groupsDbReference = collection(getFirestore(), groupsPaths.COLLECTION_GROUPS);
  // Returning the query
  return query(
    groupsDbReference,
    // Checking company based documents
    where(groupsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking status based documents - processing/template/archive/removed
    where(groupsPaths.FIELD_ACTIVE, '==', true),
    // Ordering by created date
    orderBy(orderByCategory ? orderByCategory : groupsPaths.FIELD_DISPLAY_NAME, categoryOrderSort ?? 'asc'),
    // Pagination
    startAt(pageNumber ? pageNumber * (limitNumber ?? DEFAULT_NUMBER_OF_DATA) : 0),
    // If no limit provided the default is 150
    limit(limitNumber ?? DEFAULT_NUMBER_OF_DATA),
  );
}

export function queryFetchGroupMembersByGroupUid(
  groupUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // The document reference in which the query should do fetching
  const groupsDbReference = collection(
    getFirestore(),
    groupsPaths.COLLECTION_GROUPS,
    groupUid,
    groupsPaths.SUB_COLLECTION_MEMBERS,
  );
  // Returning the query
  return query(
    groupsDbReference,
    // Checking status based documents - processing/template/archive/removed
    where(groupsPaths.FIELD_ACTIVE, '==', true),
    where(groupsPaths.FIELD_STATUS, '==', 'active'),
    // Ordering by created date
    orderBy(orderByCategory ? orderByCategory : groupsPaths.FIELD_DISPLAY_NAME, categoryOrderSort ?? 'asc'),
    // Pagination
    startAt(pageNumber ? pageNumber * (limitNumber ?? DEFAULT_NUMBER_OF_DATA) : 0),
    // If no limit provided the default is 150
    limit(limitNumber ?? DEFAULT_NUMBER_OF_DATA),
  );
}

export function getGroupMemberReference(group_uid: string, member_uid: string) {
  return doc(getFirestore(), groupsPaths.COLLECTION_GROUPS, group_uid, 'members', member_uid);
}

export function getGroupNewMemberReference(group_uid: string) {
  return doc(collection(getFirestore(), groupsPaths.COLLECTION_GROUPS, group_uid, 'members'));
}

export function getNewGroupReference() {
  // The document reference in which the query should do fetching
  return doc(collection(getFirestore(), groupsPaths.COLLECTION_GROUPS));
}

export function getGroupReference(uid: string) {
  // The document reference in which the query should do fetching
  return doc(getFirestore(), groupsPaths.COLLECTION_GROUPS, uid);
}
