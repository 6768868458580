/**
 * @description - The Documents application. Contains Documentations processing and management platforms.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useRoutes } from 'react-router-dom';
// Contexts
import { BisflowThemeProvider, FirebaseAuthProvider } from 'src/contexts';
// Local components
import {
  NotistackProvider,
  MotionLazyContainer,
  ProgressBarStyle,
  Settings,
  ScrollTop,
} from 'src/components';
// Local types
import { TypeRouteItem } from 'src/@types';
// Routes
import { authRoutes, contractRoutes } from 'src/routes';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - Application based related routes.
 */
const routes: TypeRouteItem[] = [ authRoutes, contractRoutes ];

/**
 * @description - Bisflow application's administrative view.
 * @constructor
 */
export default function BisflowDocumentsApp() {
  return (
    <BisflowThemeProvider>
      {/* Notification provider */}
      <NotistackProvider>
        {/* Authentication provider */}
        <FirebaseAuthProvider>
          <MotionLazyContainer>
            <ProgressBarStyle />
            {/*<Settings />*/}
            <ScrollTop />
            {/* ToDo implement the Google Analytics */}
            { useRoutes(routes) }
          </MotionLazyContainer>
        </FirebaseAuthProvider>
      </NotistackProvider>
    </BisflowThemeProvider>
  );
}
