/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH, PATH_APP_MAIN } from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Navigation configuration
import { docsNavBarConfig } from './index';
// Wrapper
import DashboardPageWithBreadcrumbs from 'src/layouts/dashboard/views/DashboardPageWithBreadcrumbs';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const MaintenancePage = Loadable(lazy(() => import('src/pages/maintenance/index')));
const PermissionDeniedPage = Loadable(lazy(() => import('src/pages/access/PagePermissionDenied')));
const DashboardPage = Loadable(lazy(() => import('src/pages/docs/dashboard/DashboardPage')));
const DocumentsListPage = Loadable(lazy(() => import('src/pages/docs/documents/DocumentsListPage')));
const DocumentPageNewEdit = Loadable(lazy(() => import('src/pages/docs/documents/DocumentPageNewEdit')));
const EmailsListPage = Loadable(lazy(() => import('src/pages/docs/communication/EmailsListPage')));
const TemplatesListPage = Loadable(lazy(() => import('src/pages/docs/templates/TemplatesListPage')));
const TemplatePageNewEdit = Loadable(lazy(() => import('src/pages/docs/templates/TemplatePageNewEdit')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const docsRoutes: TypeRouteItem = {
  path: PATH_APP_DOCS.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <PlatformAccessGuard path={PATH_APP_MAIN.root}>
        <DashboardLayout navConfig={docsNavBarConfig} />
      </PlatformAccessGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_DOCS.dashboardFull} replace />, index: true },
    // Dashboard
    {
      path: PATH_APP_DOCS.dashboard,
      element: <DashboardPage />
    },
    // Process section - REDIRECT -
    {
      path: PATH_APP_DOCS.active.root,
      element: <Navigate to={PATH_APP_DOCS.active.contractsFull} replace />,
      index: true
    },
    // Process section - CONTRACTS -
    {
      path: PATH_APP_DOCS.active.contracts,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Documents/Contracts List'}
        onChangeToPath={PATH_APP_DOCS.active.contractCreateFull}
        buttonName={'New Contract'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentsListPage />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contractCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Create Document/Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contractsFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentPageNewEdit isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contractEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Document/Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contractFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentPageNewEdit isEditPage={true} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contractRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_DOCS.active.contractsFull} replace />,
      index: true,
    },
    {
      path: PATH_APP_DOCS.active.contract,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Document/Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contractEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentPageNewEdit isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    // Process section - ACTS -
    {
      path: PATH_APP_DOCS.active.acts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    {
      path: PATH_APP_DOCS.active.actCreate,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    {
      path: PATH_APP_DOCS.active.actEdit,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    {
      path: PATH_APP_DOCS.active.act,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Process section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.active.documents,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    {
      path: PATH_APP_DOCS.active.documentCreate,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    {
      path: PATH_APP_DOCS.active.documentEdit,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    {
      path: PATH_APP_DOCS.active.document,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },

    // Personnel section - REDIRECT -
    {
      path: PATH_APP_DOCS.archive.root,
      element: <Navigate to={PATH_APP_DOCS.archive.contractsFull} replace />,
      index: true,
    },
    // Personnel section - CONTRACTS -
    {
      path: PATH_APP_DOCS.archive.contracts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Personnel section - ACTS -
    {
      path: PATH_APP_DOCS.archive.acts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Personnel section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.archive.documents,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Files section - TEMPLATES -
    {
      path: PATH_APP_DOCS.templates.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Templates List'}
        onChangeToPath={PATH_APP_DOCS.templates.templateCreateFull}
        buttonName={'New Template'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <TemplatesListPage />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.templates.templateRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_DOCS.templates.rootFull} replace />,
      index: true,
    },
    {
      path: PATH_APP_DOCS.templates.templateCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Create Template'}
        onChangeToPath={PATH_APP_DOCS.templates.rootFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <TemplatePageNewEdit isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.templates.templateEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Template'}
        onChangeToPath={PATH_APP_DOCS.templates.templateFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <TemplatePageNewEdit isEditPage={true} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.templates.template,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Template'}
        onChangeToPath={PATH_APP_DOCS.templates.templateEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <TemplatePageNewEdit isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },

    // Reports section - REDIRECT -
    {
      path: PATH_APP_DOCS.reports.root,
      element: <Navigate to={PATH_APP_DOCS.reports.contractsFull} replace />
    },
    // Reports section - CONTRACTS -
    {
      path: PATH_APP_DOCS.reports.contracts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Reports section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.reports.documents,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Reports section - ACTS -
    {
      path: PATH_APP_DOCS.reports.acts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },

    // Analytics section - REDIRECT -
    {
      path: PATH_APP_DOCS.analytics.root,
      element: <Navigate to={PATH_APP_DOCS.analytics.contractsFull} replace />
    },
    // Reports section - CONTRACTS -
    {
      path: PATH_APP_DOCS.analytics.contracts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Reports section - DOCUMENTS -
    {
      path: PATH_APP_DOCS.analytics.documents,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Reports section - ACTS -
    {
      path: PATH_APP_DOCS.analytics.acts,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    },
    // Communication - EMAIL -
    {
      path: PATH_APP_DOCS.communication.email,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    }
  ]
};

export default docsRoutes;
