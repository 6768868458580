/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  getDocs,
  doc,
  query,
  where,
  orderBy,
  startAt,
  limit,
  collection,
  Timestamp,
} from 'firebase/firestore';
// Paths
import { budgetsPaths, DEFAULT_NUMBER_OF_DATA } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export function queryGetActiveBudgetsTotalNumberByCompanyUid(
  companyUid: string,
) {
  // The employees reference in which the query should do fetching
  const queryDbReference = collection(getFirestore(), budgetsPaths.COLLECTION_BUDGETS);
  return query(
    queryDbReference,
    // Checking company based documents
    where(budgetsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking status based documents - processing/template/archive/removed
    where(budgetsPaths.FIELD_ACTIVE, '==', true),
  );
}

export function queryGetBudgetsListByCompanyUid(
  companyUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // The employees reference in which the query should do fetching
  const queryDbReference = collection(getFirestore(), budgetsPaths.COLLECTION_BUDGETS);
  // Returning the query
  return query(
    queryDbReference,
    // Checking company based documents
    where(budgetsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking status based documents - processing/template/archive/removed
    where(budgetsPaths.FIELD_ACTIVE, '==', true),
    // Ordering by created date
    orderBy(orderByCategory ? orderByCategory : budgetsPaths.FIELD_DISPLAY_NAME, categoryOrderSort ?? 'asc'),
    // Pagination
    startAt(pageNumber ? pageNumber * (limitNumber ?? DEFAULT_NUMBER_OF_DATA) : 0),
    // If no limit provided the default is 150
    limit(limitNumber ?? DEFAULT_NUMBER_OF_DATA),
  );
}

export function queryGetBudgetLinesByBudgetUid(
  budgetUid: string,
)  {
  const queryDbReference = collection(getFirestore(), budgetsPaths.COLLECTION_BUDGETS, budgetUid, 'lines');
  // Returning the query
  return query(
    queryDbReference,
    where(budgetsPaths.FIELD_ACTIVE, '==', true),
    orderBy(budgetsPaths.FIELD_DISPLAY_NAME, 'asc'),
  )
}

export function getNewBudgetReference() {
  // The document reference in which the query should do fetching
  return collection(getFirestore(), budgetsPaths.COLLECTION_BUDGETS);
}

export function getNewBudgetReferenceDoc() {
  return doc(collection(getFirestore(), budgetsPaths.COLLECTION_BUDGETS));
}

export function getBudgetReference(uid: string) {
  // The document reference in which the query should do fetching
  return doc(getFirestore(), budgetsPaths.COLLECTION_BUDGETS, uid);
}

export function getBudgetLineReference(uid: string, lineUid: string) {
  // The document reference in which the query should do fetching
  return doc(getFirestore(), budgetsPaths.COLLECTION_BUDGETS, uid, 'lines', lineUid);
}

export function getBudgetLineNewReference(uid: string) {
  // The document reference in which the query should do fetching
  return doc(collection(getFirestore(), budgetsPaths.COLLECTION_BUDGETS, uid, 'lines'));
}
