/**
 * @description - The guest guarding wrapper component. allowing to get access without authentication. If the user
 * logged in, then user will be redirected to the PATH_ROOTS_APP path.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// React
import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
// hooks
import { useAuth } from 'src/hooks';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type GuestGuardProps = {
  children: ReactNode;
  path: string;
};

/**
 * @description - The guest guarding wrapper component.
 * @param children
 * @param path
 * @constructor
 */
export default function GuestGuard({ children, path }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  // const { id } = useParams();
  if (isAuthenticated) {
    // if (id) {
    //   return <Navigate to={path + '/' + id}/>;
    // } else {
    // }
    return <Navigate to={path}/>;
  }

  return <>{children}</>;
}
