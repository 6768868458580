// React
import { ReactNode } from 'react';
// Local components
import { Page, HeaderBreadcrumbs, Iconify, Scrollbar } from 'src/components';
import { useAuth, useSettings } from 'src/hooks';
// Material components
import { Button, Container } from '@mui/material';
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { PATH_APP_PLATFORM } from 'src/routes';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type DashboardPageWithBreadcrumbsProp = {
  children: ReactNode;
  pageTitle: string;
  pageSubTitle: string;
  buttonName: string;
  platformName: string;
  onChangeToPath?: string | ((id: string) => string);
};

/**
 * @description - The wrapper component that contains list edit create and view children
 * @constructor
 */
export default function DashboardPageWithBreadcrumbs({
  children,
  pageTitle,
  pageSubTitle,
  buttonName,
  platformName,
  onChangeToPath,
}: DashboardPageWithBreadcrumbsProp) {
  // The settings for the view size
  const { themeStretch } = useSettings();
  const { employeeProfile } = useAuth();
  const allowEdit = employeeProfile?.platforms?.[platformName]?.write === true;

  // Getting product id if you need to redirect to its generator method
  const { id } = useParams();
  // The path in order to generate the links
  const { pathname } = useLocation();
  let currentPath = '';

  const getLinks = () => {
    let currentLinks;
    if (pathname?.length > 0) {
      currentLinks = pathname.split('/')
        .map((path) => {
          currentPath += `${path}/`;
          return {
            name: path,
            href: currentPath
          }
        });
      currentLinks.shift();
    }
    // Checking of the current links ara available
    return currentLinks ?? [
      {
        name: 'App',
        href: PATH_APP_PLATFORM.rootFull
      }
    ]
  }
  const itemisationLinks = getLinks();
  const isCancel = buttonName?.toLowerCase() === 'cancel';
  const isEdit = buttonName?.toLowerCase() === 'edit';
  let redirectPath = typeof onChangeToPath === 'string'
    ? onChangeToPath
    : typeof onChangeToPath === 'function' && id
      ? onChangeToPath(id)
      : itemisationLinks?.[itemisationLinks?.length -2]?.href;
  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={pageSubTitle}
          links={itemisationLinks}
          action={
            allowEdit && onChangeToPath && (<Button
              variant={isCancel ? "outlined" : "contained"}
              component={RouterLink}
              to={redirectPath}
              color={isCancel ? 'inherit' : 'primary'}
              startIcon={<Iconify icon={
                isCancel
                  ? 'eva:close-outline'
                  :  isEdit
                    ? 'eva:edit-2-outline'
                    : 'eva:plus-fill'
              } />}
            >
              {buttonName}
            </Button>)
          }
        />
        {children}
      </Container>
    </Page>
  )
}