/**
 * @description - The documents page related slices
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { TypeDocumentTypeModel, TypeDocumentContentSectionModel } from 'src/@types';
// API
// import {
//   fetchAllDocumentsFromProject,
//   fetchAllTemplatesByCompanyUid,
//   fetchDocumentContentByUid,
//   fetchDocumentByUid,
// } from 'src/services/documents/documents';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

type DocumentsState = {
  isLoading: boolean;
  error: Error | string | null;
  documents: TypeDocumentTypeModel[];
  document: TypeDocumentTypeModel | null;
  documentContent: TypeDocumentContentSectionModel | null;
};

const documentsInitialState: DocumentsState = {
  isLoading: false,
  error: null,
  documents: [],
  document: null,
  documentContent: null,
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState: documentsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET TEMPLATES
    getDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },
    getDocumentByUidSuccess(state, action) {
      state.isLoading = false;
      state.document = action.payload;
    },
    getContentByDocumentUidSuccess(state, action) {
      state.isLoading = false;
      state.documentContent = action.payload;
    }
  }
});

export default documentsSlice.reducer;
export const {} = documentsSlice.actions;

export function getContractTemplatesByProjectUid(projectUid: string, userUid: string, companyUid: string) {
  return () => {
    dispatch(documentsSlice.actions.startLoading());
    // try {
    //   fetchAllDocumentsFromProject(
    //     'contract',
    //     'template',
    //     projectUid,
    //     companyUid,
    //     userUid,
    //     (contractTemplates) => dispatch(documentsSlice.actions.getDocumentsSuccess(contractTemplates)),
    //     (error) => dispatch(documentsSlice.actions.hasError(error))
    //   );
    // } catch (error) {
    //   dispatch(documentsSlice.actions.hasError(error));
    // }
  }
}

export function getTemplatesByCompanyUid(userUid: string, companyUid: string) {
  return () => {
    dispatch(documentsSlice.actions.startLoading());
    // try {
    //   fetchAllTemplatesByCompanyUid(
    //     userUid,
    //     companyUid,
    //     (templates) => dispatch(documentsSlice.actions.getDocumentsSuccess(templates)),
    //     (error) => dispatch(documentsSlice.actions.hasError(error))
    //   );
    // } catch (error) {
    //   dispatch(documentsSlice.actions.hasError(error));
    // }
  }
}

export function getTemplateContentByUid(documentUid: string) {
  return () => {
    dispatch(documentsSlice.actions.startLoading());
    // try {
    //   fetchDocumentContentByUid(
    //     documentUid,
    //     'template',
    //     (documentContent) => dispatch(documentsSlice.actions.getContentByDocumentUidSuccess(documentContent)),
    //     (error) => dispatch(documentsSlice.actions.hasError(error))
    //   );
    // } catch (error) {
    //   dispatch(documentsSlice.actions.hasError(error.message))
    // }
  }
}

export function getTemplateByUid(documentUid: string) {
  return () => {
    dispatch(documentsSlice.actions.startLoading());
    // try {
    //   fetchDocumentByUid(
    //     documentUid,
    //     'template',
    //     (document) => dispatch(documentsSlice.actions.getDocumentsSuccess(document)),
    //     (error) => dispatch(documentsSlice.actions.hasError(error))
    //   );
    // } catch (error) {
    //   dispatch(documentsSlice.actions.hasError(error.message))
    // }
  }
}
