/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH, PATH_APP_MAIN } from 'src/routes';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';
// Layouts
import { LogoOnlyLayout } from 'src/layouts';
// Navigation configuration
// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const DocsListPage = Loadable(lazy(() => import('src/pages/docs/views/docs-list-page')));
const DocumentProcessPage = Loadable(lazy(() => import('src/pages/docs/files/contract-process-page')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const docsRoutes: TypeRouteItem = {
  path: '/',
  element: (
    <AuthGuard path={'/auth/contracts'}>
      <LogoOnlyLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={'contracts'} replace />, index: true },
    {
      path: 'contracts',
      element: <DocumentProcessPage  />
      // element:  <DocsListPage  />
    },
    {
      path: 'contracts/:id',
      element: <DocumentProcessPage  />
    }
  ]
};

export default docsRoutes;
