/**
 * @description - The all services that are related to the team data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Common fetching data
import { fetchTotalNumberByCompanyUid } from '../common';
// Config
import { employeesPaths } from 'src/configs';
// Firestore
import { getDocs, updateDoc, addDoc, getDoc, Timestamp } from 'firebase/firestore';
// Queries
import {
  queryGetEmployeesListByCompanyUid,
  getNewEmployeeReference,
  getEmployeeReference,
} from './queries';
// Types
import { TypeEmployeeProfile } from 'src/@types';
// Converters
import { toEmployeeProfile } from 'src/services/user/models';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is getting total number of the employees
 * @param company_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchTeamTotalNumberByCompanyUid(
  company_uid: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: Error | string) => void,
) {
  fetchTotalNumberByCompanyUid(company_uid, employeesPaths.COLLECTION_EMPLOYEES, onSuccess, onFail);
}

/**
 * @description - The method is fetching employees list data for the company
 * @param company_uid
 * @param onSuccess
 * @param onFail
 * @param orderByCategory
 * @param categoryOrderSort
 * @param limitNumber
 * @param pageNumber
 */
export function fetchTeamByCompanyUid(
  company_uid: string,
  onSuccess: (employees: TypeEmployeeProfile[]) => void,
  onFail: (error: Error | string) => void,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // Generating query
  const queryDetails = queryGetEmployeesListByCompanyUid(company_uid);
  if (queryDetails) {
    getDocs(queryDetails)
      .then((employeeSnapshots) => {
        const employees: TypeEmployeeProfile[] = [];
        employeeSnapshots.forEach((profileSnapshot) => {
          const profileData = profileSnapshot.data();
          if (profileData) {
            const employeeProfile = toEmployeeProfile(profileData);
            if (employeeProfile) {
              employees.push(employeeProfile);
            }
          }
        });
        onSuccess(employees);
      })
      .catch((error) => onFail(error.message));
  }
}

/**
 * @description - The method is fetching user's employee detailed view
 * @param userUid
 * @param onSuccess
 * @param onFail
 */
export function fetchTeamMemberByUserUid(
  userUid: string,
  onSuccess: (member: TypeEmployeeProfile) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getEmployeeReference(userUid);
  getDoc(queryDetails)
    .then((employeeSnapshot) => {
      if (employeeSnapshot.exists()) {
        const profileData = employeeSnapshot.data();
        if (profileData) {
          const employeeProfile = toEmployeeProfile(profileData);
          if (employeeProfile) {
            onSuccess(employeeProfile);
          } else {
            onFail(`Something went wrong during fetching user data ${userUid}`);
          }
        } else {
          onFail(`Something went wrong during fetching user data ${userUid}`);
        }
      } else {
        onFail(`User's employee data does not exist ${userUid}`);
      }
    })
    .catch((error) => onFail(error.message))
}

export function updateEmployeeByUid(
  userUid: string,
  data: Partial<TypeEmployeeProfile>,
  onSuccess: VoidFunction,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getEmployeeReference(userUid);
  updateDoc(queryDetails, { ...data, updated: Timestamp.now() }).then(onSuccess).catch((error) => onFail(error.message));
}

export function createEmployee(
  data: Partial<TypeEmployeeProfile>,
  onSuccess: (employeeData: TypeEmployeeProfile) => void,
  onError: (error: Error | string) => void,
) {
  const reference = getNewEmployeeReference();
  const employeeData = {
    ...data,
    active: true,
    created: Timestamp.now(),
    updated: Timestamp.now(),
    status: 'active',
  }
  addDoc(reference, employeeData).then(() => onSuccess(employeeData as TypeEmployeeProfile)).catch(onError);
}
